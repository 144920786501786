import React, { useEffect, useState } from 'react'
import { Card, Table, Button, Tag } from 'antd'
import { API } from 'aws-amplify'
import Sentry from 'src/utils/sentry'
import { SuccessNotification } from 'src/notifications/SuccessNotification'

const SpecialPurchaseOrderDetail = () => {
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get('token')

  const [order, setOrder] = useState(null)

  const handleApprove = async () => {
    await sendApproveAndDeclineStatus({
      isApproved: true,
      isDeclined: false,
      token,
    })
    setOrder({ ...order, isApproved: true, isDeclined: false })
  }

  const handleReject = async () => {
    await sendApproveAndDeclineStatus({
      isApproved: false,
      isDeclined: true,
      token,
    })
    setOrder({ ...order, isApproved: false, isDeclined: true })
  }

  const getPuchaseOrderDetail = async token => {
    try {
      const response = await API.get(
        'api2',
        `specialPuurchases/getPuchaseOrderDetail?token=${token}`,
      )

      if (response.success) {
        setOrder(response.data)
      }
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
      return false
    }
  }

  const sendApproveAndDeclineStatus = async data => {
    try {
      const response = await API.put(
        'api2',
        `specialPuurchases/sendApproveAndDeclineStatus`,
        { body: data },
      )

      if (response.success) {
        SuccessNotification({ message: response?.message })
      }
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
      return false
    }
  }

  useEffect(() => {
    if (token) {
      getPuchaseOrderDetail(token)
    }
  }, [token])

  const columns = [
    {
      title: 'Item Name',
      dataIndex: 'itemName',
      key: 'itemName',
    },
    {
      title: 'Item Cost',
      dataIndex: 'itemCost',
      key: 'itemCost',
      render: cost => `£${cost}`,
    },
    {
      title: 'Status',
      key: 'status',
      render: () =>
        order.isApproved ? (
          <Tag color='green'>Approved</Tag>
        ) : order.isDeclined ? (
          <Tag color='red'>Rejected</Tag>
        ) : (
          <Tag color='orange'>Pending</Tag>
        ),
    },
  ]

  if (order == null) return 'loading ...'
  return (
    <Card
      title='Order Details'
      bordered
      style={{ maxWidth: 800, margin: '20px auto' }}
    >
      <p>
        <strong>Manager:</strong> {order.managerDetails.managerName} (
        {order.managerDetails.managerEmail})
      </p>
      <p>
        <strong>Staff:</strong> {order.staffDetails.staffName} (
        {order.staffDetails.staffEmail})
      </p>
      <Table
        dataSource={[order.itemDetails]}
        columns={columns}
        pagination={false}
        rowKey='itemName'
      />

      <div style={{ marginTop: 20, textAlign: 'right' }}>
        <Button
          type='primary'
          onClick={handleApprove}
          style={{ marginRight: 10 }}
          disabled={order.isApproved}
        >
          Approve
        </Button>
        <Button
          type='danger'
          onClick={handleReject}
          disabled={order.isDeclined}
        >
          Reject
        </Button>
      </div>
    </Card>
  )
}

export default SpecialPurchaseOrderDetail
