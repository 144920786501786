import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Tabs, Card, Button, List, Popconfirm } from 'antd'
import RecipeManager from './components/RecipeManager'
import ProductModal from './components/ProductModal'
import AllergenGrid from './components/AllergenGrid'
import RecipeDetails from './components/RecipeDetails'
import { useDispatch, useSelector } from 'react-redux'
import { getProducts } from 'src/features/MenuBuilderSlice'
import {
  createRecipe,
  deleteRecipe,
  editRecipe,
  fetchAllRecipes,
  fetchCategories,
} from 'src/features/RecipeBuilderSlice'
import { DeleteOutlined } from '@ant-design/icons'

const TabPane = Tabs.TabPane

const DashboardContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px;
  width: 100%;
`

const Title = styled.h1`
  font-size: 28px;
  margin-bottom: 24px;
`

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`
const Banner = styled.div`
  background-color: rgb(216, 241, 161); /* Bright parrot green */
  color: rgb(33, 78, 34); /* Deep green for contrast */
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  padding: 18px;
  text-align: center;
  font-size: 18px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  strong {
    font-weight: 600;
    color: #186a3b; /* Darker green for emphasis */
  }

  .emoji {
    font-size: 20px;
  }

  a {
    color: #1b5e20; /* Darker parrot green */
    font-weight: 600;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
  }

  a:hover {
    color: #0f3d15; /* Even darker green on hover */
    text-decoration: underline;
  }
`

const RecipeDashboard = () => {
  const dispatch = useDispatch()
  const venueId = useSelector(state => state.venue?.venue?._id)
  const venueTitle = useSelector(state => state.venue?.venue?.title)
  const products = useSelector(state => state.menus.products)
  const { recipes, categories } = useSelector(state => state.recipes)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [selectedRecipe, setSelectedRecipe] = useState(null)

  const handleSaveRecipe = newRecipe => {
    dispatch(createRecipe(venueId, newRecipe))

    // if (newRecipe.id) {
    //   setRecipes(recipes.map((r) => (r.id === newRecipe.id ? newRecipe : r)));
    // } else {
    //   setRecipes([...recipes, { ...newRecipe, id: Date.now().toString() }]);
    // }
  }

  // console.log('recipes ---> ', recipes)
  // console.log('selectedRecipe ----> ', selectedRecipe)

  const closeProductModal = () => {
    setSelectedProduct(null)
  }

  const handleEditRecipe = recipeData => {
    dispatch(editRecipe(recipeData?._id, recipeData, venueId))
  }

  useEffect(() => {
    dispatch(getProducts(venueId))
    dispatch(fetchAllRecipes(venueId))
    dispatch(fetchCategories(venueId))
  }, [dispatch, venueId])

  return (
    <DashboardContainer>
      <Title>Recipe Management Dashboard</Title>
      <Banner>
        🎉 This is a BETA OMS Plus Feature. 🎉 📩 For submitting feedback, email
        us at{' '}
        <a href='mailto:info@ordersmadesimple.com'>info@ordersmadesimple.com</a>
        .Thank you!
      </Banner>

      <Tabs defaultActiveKey='recipes'>
        <TabPane tab='Recipes' key='recipes'>
          <GridContainer>
            <RecipeManager
              products={products}
              categories={categories}
              onSaveRecipe={handleSaveRecipe}
            />

            <Card title='Recipe List'>
              <List
                dataSource={recipes}
                renderItem={recipe => (
                  <List.Item
                    actions={[
                      <Button
                        key={recipe._id}
                        type='link'
                        onClick={() => setSelectedRecipe(recipe)}
                      >
                        View Details
                      </Button>,
                      <Popconfirm
                        key={recipe._id}
                        title='Are you sure to delete the recipe?'
                        placement='topRight'
                        onConfirm={() => {
                          setSelectedRecipe(null)
                          dispatch(deleteRecipe(recipe._id, venueId))
                        }}
                        okText='Yes'
                        cancelText='No'
                      >
                        <DeleteOutlined
                          style={{ color: 'red', fontSize: 21 }}
                        />
                      </Popconfirm>,
                    ]}
                  >
                    <List.Item.Meta
                      title={recipe?.title}
                      description={`£${recipe?.sellingPrice?.toFixed(
                        2,
                      )} | Gross Profit: ${parseFloat(recipe?.grossProfit)}%`}
                    />
                  </List.Item>
                )}
              />
            </Card>
          </GridContainer>

          {selectedRecipe && (
            <RecipeDetails
              key={selectedRecipe?._id}
              recipe={selectedRecipe}
              products={products}
              onSave={handleEditRecipe}
            />
          )}
        </TabPane>

        {/* <TabPane tab='Products' key='products'>
          <Card title='Product Catalog'>
            <List
              grid={{ gutter: 16, column: 3 }}
              dataSource={products}
              renderItem={product => (
                <List.Item>
                  <Card hoverable onClick={() => handleProductClick(product)}>
                    <Card.Meta
                      title={product.name}
                      description={`£${product.price.toFixed(2)}`}
                    />
                  </Card>
                </List.Item>
              )}
            />
          </Card>
        </TabPane> */}

        <TabPane tab='Allergen Grid' key='allergens'>
          <AllergenGrid
            dishes={recipes?.map(recipe => ({
              name: recipe?.title,
              allergens: Array.from(
                new Set(
                  recipe.ingredients.flatMap(
                    ing => ing.product.allergens || [],
                  ),
                ),
              ),
            }))}
            businessName={venueTitle}
          />
        </TabPane>
      </Tabs>

      <ProductModal
        visible={!!selectedProduct}
        onClose={closeProductModal}
        product={selectedProduct}
      />
    </DashboardContainer>
  )
}

export default RecipeDashboard
