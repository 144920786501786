import { useEffect, useMemo, useState, useRef } from 'react'
import styled from 'styled-components'
import {
  Card,
  Input,
  InputNumber,
  Button,
  Icon,
  Form,
  Typography,
  Modal,
  notification,
} from 'antd'
import SearchableSelect from './ui/SearchableSelect'
import {
  calculateTotalPrice,
  extractAndCalculateSize,
} from 'src/utils/priceFunctions'
import { CloseOutlined } from '@ant-design/icons'
import CustomCategorySearch from './CustomCategorySearch'
import { useDispatch, useSelector } from 'react-redux'
import { setSuccess } from 'src/features/RecipeBuilderSlice'
import RecipeNotes from './ui/RecipeNotes'
import MethodSection from './ui/MethodSection'
import { uploadFile, deleteFile } from 'src/utils/uploadFile'

// const { Option } = Select;
const { TextArea } = Input
const { Title, Text } = Typography

const StyledCard = styled(Card)`
  .ant-card-head-title {
    font-size: 18px;
  }
`

const IngredientRow = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 4px;
  background: #fafafa;

  .ant-form-item {
    margin-bottom: 0;
  }

  .delete-button {
    padding-bottom: 8px;
  }
`

const FileUpload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  img {
    max-width: 700px;
    height: 300px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  button {
    background: #b5d922;
    color: #fff;
    width: 100%;
  }
`

const initialState = {
  title: '',
  description: '',
  portions: 1,
  sellingPrice: '',
  totalIngredientsPrice: '',
  costPerPortion: '',
  costPerRecipe: '',
  grossProfit: '',
  category: '',
  servingSize: 400,
  image: null,
  ingredients: [
    {
      sku: '',
      title: '',
      size: '',
      price: '',
      UoM: '',
      totalPrice: '',
    },
  ],
  notes: {
    text: '',
    images: null,
  },
  steps: [''],
}

const RecipeManager = ({ products, categories, onSaveRecipe }) => {
  const { isLoading, error, success } = useSelector(state => state.recipes)
  const dispatch = useDispatch()
  const fileInputRef = useRef(null)
  const [visible, open] = useState(false)
  const [openCategoryDropdown, setOpenCategoryDropdown] = useState(false)
  const [formState, setFormState] = useState(initialState)

  const { sellingPrice, costPerRecipe, costPerPortion, portions, grossProfit } =
    formState
  const [isUploading, setIsUploading] = useState(false)
  const [localImageUrl, setLocalImageUrl] = useState(null)
  const [uploadingIndexes, setUploadingIndexes] = useState([])

  const getuniqueSize = useMemo(() => {
    const uniqueSizes = products.reduce((acc, venueProduct) => {
      const { product } = venueProduct ?? {}
      if (isNaN(product.size) && !acc.includes(product.size)) {
        acc.push({ size: product.size, price: product.price, UoM: product.UoM })
      }
      return acc
    }, [])
    return uniqueSizes
  }, [products])
  console.log('formState -----> ', formState)
  console.log('getuniqueSize -----> ', getuniqueSize)

  const handleChange = (key, value) => {
    setFormState(prev => ({ ...prev, [key]: value }))
  }

  const handleIngredientChange = (index, key, value) => {
    const updatedIngredients = [...formState.ingredients]
    if (key === 'productId') {
      const { product, _id } = products.find(p => p._id === value)
      let extractSize = extractAndCalculateSize(product.size)
      updatedIngredients[index] = {
        ...updatedIngredients[index],
        ...product,
        productId: _id,
        sku: product.sku || '',
        title: product.title || '',
        size: extractSize.numbers[0],
        totalPrice:
          calculateTotalPrice(
            product.size,
            product.price,
            extractSize.numbers[0],
          ) || '',
      }
    } else {
      updatedIngredients[index] = { ...updatedIngredients[index], [key]: value }
      if (key === 'size' && updatedIngredients[index].productId) {
        const product = products.find(
          p => p._id === updatedIngredients[index].productId,
        ).product
        const totalPrice = calculateTotalPrice(
          product.size,
          updatedIngredients[index].price,
          updatedIngredients[index].size,
        )
        updatedIngredients[index] = { ...updatedIngredients[index], totalPrice }
      }
    }
    setFormState(prev => ({ ...prev, ingredients: updatedIngredients }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    const validIngredients = formState.ingredients
      .filter(ing => ing && ing.productId)
      .map(item => {
        return {
          product: item?._id,
          size: item.size,
        }
      })

    if (!validIngredients.length) {
      notification.error({
        message: 'No Ingredients',
        description: 'Please select at least one ingredient.',
      })
      return
    }

    const validSteps = formState?.steps?.filter(step => step != '')
    onSaveRecipe({
      ...formState,
      ingredients: validIngredients,
      steps: validSteps,
    })
  }

  useEffect(() => {
    if (!isLoading && !error && success) {
      open(false)
      setFormState(initialState)
      setLocalImageUrl(null)
      setOpenCategoryDropdown(false)
      dispatch(setSuccess(false))
    }
  }, [isLoading, error, success])

  console.log('isLoading, error, success---->', isLoading, error, success)

  // Calculate gross profit percentage when selling price or cost changes
  useEffect(() => {
    if (sellingPrice && costPerPortion) {
      const profit = ((sellingPrice - costPerPortion) / sellingPrice) * 100
      handleChange('grossProfit', Number(profit.toFixed(1)))
    }
  }, [sellingPrice, costPerPortion])

  useEffect(() => {
    if (costPerRecipe && portions) {
      const portionPrice = costPerRecipe / portions
      handleChange('costPerPortion', Number(portionPrice.toFixed(1)))
    }
  }, [costPerRecipe, portions])

  useEffect(() => {
    if (formState.ingredients.length) {
      handleChange(
        'costPerRecipe',
        formState.ingredients.reduce((acc, num) => acc + num.totalPrice, 0),
      )
    }
  }, [formState.ingredients])

  const handleFileChange = async e => {
    const selectedFile = e.target.files[0]
    if (!selectedFile) return

    const localUrl = URL.createObjectURL(selectedFile)
    setLocalImageUrl(localUrl)

    setIsUploading(true)

    try {
      const uploadedData = await uploadFile(selectedFile)
      if (uploadedData) {
        handleChange('image', uploadedData)
      }
    } catch (error) {
      console.error('Error uploading file:', error)
      notification.error({
        message: 'Upload Failed',
        description:
          'There was an error uploading the image. Please try again.',
      })
    } finally {
      setIsUploading(false)
    }
  }

  const handleDeleteImage = async () => {
    if (!formState.image) return

    const success = await deleteFile(formState.image.keyName)
    if (success) {
      handleChange('image', null)
      setLocalImageUrl(null)
    }
  }

  const handleClose = () => {
    setOpenCategoryDropdown(false)
    open(false)
    setFormState(initialState)
  }
  return (
    <div>
      <Button type='primary' onClick={() => open(true)}>
        Create New Recipe
      </Button>
      <Modal
        visible={visible}
        title='Create New Recipe'
        onCancel={handleClose}
        onClick={() => {
          console.log('form clicked ')
        }}
        footer={null}
        width={1200}
      >
        <StyledCard>
          <Form onSubmit={handleSubmit} layout='vertical'>
            <Form.Item
              label='Recipe Name'
              onClick={() => setOpenCategoryDropdown(false)}
            >
              <Input
                value={formState.title}
                onChange={e => handleChange('title', e.target.value)}
                required
              />
            </Form.Item>
            <Form.Item label='Category'>
              <CustomCategorySearch
                categories={categories}
                handleChange={handleChange}
                setOpen={setOpenCategoryDropdown}
                open={openCategoryDropdown}
                value={formState.category}
              />
            </Form.Item>

            <Form.Item
              label='Description'
              onClick={() => setOpenCategoryDropdown(false)}
            >
              <TextArea
                value={formState.description}
                onChange={e => handleChange('description', e.target.value)}
              />
            </Form.Item>
            <FileUpload onClick={() => setOpenCategoryDropdown(false)}>
              {localImageUrl || formState.image ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'start',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    position: 'relative',
                    width: '100%',
                    maxWidth: '500px',
                  }}
                >
                  <img
                    width='100%'
                    height='auto'
                    src={
                      formState.image
                        ? formState.image.signedURL
                        : localImageUrl
                    }
                    alt='uploaded'
                    style={{
                      opacity: isUploading ? 0.5 : 1,
                      transition: 'opacity 0.3s ease-in-out',
                      borderRadius: '8px',
                    }}
                  />
                  {isUploading && (
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: 'rgba(0, 0, 0, 0.4)',
                        borderRadius: '8px',
                      }}
                    >
                      <Icon
                        type='loading'
                        style={{ fontSize: 32, color: 'white' }}
                      />
                    </div>
                  )}

                  <CloseOutlined
                    onClick={handleDeleteImage}
                    style={{
                      position: 'absolute',
                      top: 10,
                      right: 10,
                      fontSize: '20px',
                      cursor: 'pointer',
                      color: 'white',
                      background: 'rgba(0,0,0,0.5)',
                      padding: '5px',
                      borderRadius: '50%',
                    }}
                  />
                </div>
              ) : (
                <Button
                  type='primary'
                  onClick={() => fileInputRef.current.click()}
                >
                  Upload Image
                </Button>
              )}
              <input
                type='file'
                accept='image/*'
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </FileUpload>

            {formState.ingredients.map((ingredient, index) => (
              <IngredientRow
                key={index}
                onClick={() => setOpenCategoryDropdown(false)}
              >
                <Form.Item label='SKU'>
                  <SearchableSelect
                    placeholder='SKU'
                    value={ingredient.sku || ''}
                    onChange={value =>
                      handleIngredientChange(index, 'productId', value)
                    }
                    products={products} // Pass your products here (this can be dynamic as well)
                    style={{ width: 150 }}
                    type='sku'
                  />
                </Form.Item>
                <Form.Item label='Title'>
                  <SearchableSelect
                    placeholder='Title'
                    value={ingredient.title || ''}
                    onChange={value =>
                      handleIngredientChange(index, 'productId', value)
                    }
                    products={products} // Pass your products here (this can be dynamic as well)
                    style={{ width: 400 }}
                    type='title'
                  />
                </Form.Item>
                <Form.Item label='Size'>
                  <InputNumber
                    placeholder='Size'
                    min={0}
                    value={ingredient.size || undefined}
                    onChange={value =>
                      handleIngredientChange(index, 'size', value)
                    }
                    style={{ width: 100 }}
                  />
                </Form.Item>
                <Form.Item label='UoM'>
                  <Input
                    placeholder='UoM'
                    value={ingredient.UoM || ''}
                    onChange={e =>
                      handleIngredientChange(index, 'UoM', e.target.value)
                    }
                    style={{ width: 100 }}
                    disabled
                  />
                </Form.Item>
                <Form.Item label='Price'>
                  <InputNumber
                    placeholder='Price'
                    min={0}
                    step={0.01}
                    value={ingredient.price || undefined}
                    onChange={value =>
                      handleIngredientChange(index, 'price', value)
                    }
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    style={{ width: 100 }}
                    disabled
                  />
                </Form.Item>
                <Form.Item label='Total Price'>
                  <InputNumber
                    placeholder='Total Price'
                    min={0}
                    step={0.01}
                    value={ingredient.totalPrice || undefined}
                    onChange={value =>
                      handleIngredientChange(index, 'totalPrice', value)
                    }
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    style={{ width: 100 }}
                    disabled
                  />
                </Form.Item>

                <Button
                  type='link'
                  icon='delete'
                  className='delete-button'
                  onClick={() => {
                    const newIngredients = formState.ingredients.filter(
                      (_, i) => i !== index,
                    )
                    setFormState(prev => ({
                      ...prev,
                      ingredients: newIngredients,
                    }))
                  }}
                  style={{ color: '#ff4d4f' }}
                />
              </IngredientRow>
            ))}

            <Form.Item onClick={() => setOpenCategoryDropdown(false)}>
              <Button
                type='dashed'
                onClick={() =>
                  setFormState(prev => ({
                    ...prev,
                    ingredients: [
                      ...prev.ingredients,
                      {
                        sku: '',
                        title: '',
                        size: '',
                        price: '',
                        UoM: '',
                        totalPrice: '',
                      },
                    ],
                  }))
                }
                style={{ width: '60%' }}
              >
                <Icon type='plus' /> Add Ingredient
              </Button>
            </Form.Item>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 10,
                fontSize: 20,
              }}
            >
              <Text strong>Cost Per Recipe:</Text>
              <Text strong>£{Number(costPerRecipe)?.toFixed(2) || ''}</Text>
            </div>

            <div style={{ marginBottom: 32 }}>
              <Title style={{ marginBottom: 10, fontSize: 20 }}>Portions</Title>
              <InputNumber
                style={{ width: '100%', height: 40 }}
                parser={value => value.replace(/Â£\s?/, '')}
                value={formState.portions || 1}
                onChange={value => handleChange('portions', value)}
                required
                size='large'
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 10,
                fontSize: 20,
              }}
            >
              <Text strong>Cost Per Portion:</Text>
              <Text strong>£{Number(costPerPortion)?.toFixed(2) || ''}</Text>
            </div>
            <div style={{ marginBottom: 32 }}>
              <Title style={{ marginBottom: 10, fontSize: 20 }}>
                Selling Price
              </Title>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid #d9d9d9',
                  borderRadius: '4px',
                  padding: '4px 11px',
                  height: '32px',
                  transition: 'all 0.3s',
                }}
              >
                <span
                  style={{
                    marginRight: '8px',
                    fontWeight: 'bold',
                    fontSize: '18px',
                  }}
                >
                  £
                </span>
                <input
                  type='text'
                  inputMode='decimal'
                  pattern='[0-9]*'
                  value={formState.sellingPrice || ''}
                  onChange={e => {
                    const value = e.target.value
                    // Only allow numbers and decimal point
                    if (/^\d*\.?\d*$/.test(value)) {
                      handleChange('sellingPrice', value)
                    }
                  }}
                  style={{
                    flex: 1,
                    border: 'none',
                    outline: 'none',
                    height: '100%',
                    padding: 0,
                    fontSize: '18px',
                    fontWeight: 'bold',
                  }}
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: 20,
              }}
            >
              <Text strong>Gross Profit %:</Text>
              <Text strong>{grossProfit}%</Text>
            </div>

            <RecipeNotes
              handleChange={handleChange}
              uploadingIndexes={uploadingIndexes}
              setUploadingIndexes={setUploadingIndexes}
            />
            <MethodSection handleChange={handleChange} />

            <Form.Item
              style={{
                marginBottom: 0,
                marginTop: 24,
              }}
              onClick={() => setOpenCategoryDropdown(false)}
            >
              <Button
                disabled={
                  isUploading ||
                  uploadingIndexes.some(isUploading => isUploading) ||
                  !sellingPrice ||
                  !costPerRecipe
                }
                loading={isLoading}
                onClick={handleSubmit}
                type='primary'
              >
                Save Recipe
              </Button>
            </Form.Item>
          </Form>
        </StyledCard>
      </Modal>
    </div>
  )
}

export default RecipeManager
