import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ApproveRequestModal from './components/ui/ApproveRequestModal'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllSpecialPurchases } from 'src/features/SpecialPurchasesSlice'
import { Table, Tag } from 'antd'
import dayjs from 'dayjs'

const SpecialPurchases = () => {
  const dispatch = useDispatch()
  const [approveReqeustModalVisible, setApproveReqestModalVisible] =
    useState(false)
  const { isLoading, purchases } = useSelector(state => state.purchases)
  const venueId = useSelector(state => state.venue?.venue?._id)

  useEffect(() => {
    if (venueId) {
      dispatch(fetchAllSpecialPurchases(venueId))
    }
  }, [dispatch, venueId])

  const columns = [
    {
      title: 'Manager Name',
      dataIndex: ['managerDetails', 'managerName'],
      key: 'managerName',
    },
    {
      title: 'Manager Email',
      dataIndex: ['managerDetails', 'managerEmail'],
      key: 'managerEmail',
    },
    {
      title: 'Staff Name',
      dataIndex: ['staffDetails', 'staffName'],
      key: 'staffName',
    },
    {
      title: 'Staff Email',
      dataIndex: ['staffDetails', 'staffEmail'],
      key: 'staffEmail',
    },
    {
      title: 'Item Name',
      dataIndex: ['itemDetails', 'itemName'],
      key: 'itemName',
    },
    {
      title: 'Item Cost ($)',
      dataIndex: ['itemDetails', 'itemCost'],
      key: 'itemCost',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: text => dayjs(text).format('YYYY-MM-DD'),
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, record) => (
        <>
          {record.isApproved ? (
            <Tag color='green'>Approved</Tag>
          ) : record.isDeclined ? (
            <Tag color='red'>Declined</Tag>
          ) : (
            <Tag color='orange'>Pending</Tag>
          )}
        </>
      ),
    },
  ]

  return (
    <div>
      <ApproveRequestModal
        approveReqeustModalVisible={approveReqeustModalVisible}
        setApproveReqestModalVisible={setApproveReqestModalVisible}
      />

      <StyledButton onClick={() => setApproveReqestModalVisible(true)}>
        Request Manager Approval
      </StyledButton>

      <Table
        scroll={{
          scrollToFirstRowOnChange: true,
          x: true,
        }}
        loading={isLoading}
        style={{
          marginTop: 20,
        }}
        dataSource={purchases}
        columns={columns}
        rowKey='_id'
      />
    </div>
  )
}

const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colours.lettuceGreen};
  line-height: 1.499;
  display: inline-block;
  font-weight: bold;
  border: 1px solid transparent;
  height: 41px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colours.mayoWhite};
  border-color: ${({ theme }) => theme.colours.panSilver};
  :hover {
    background-color: ${({ theme }) => theme.colours.lettuceGreen};
    opacity: 0.8;
    cursor: pointer;
  }
`

export default SpecialPurchases
