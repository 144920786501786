import { createSlice } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'
import { SuccessNotification } from 'src/notifications/SuccessNotification'
import Sentry from 'src/utils/sentry'

const initialState = {
  purchases: [],
  isLoading: false,
  error: {},
  success: false,
}

const SpecialPurchasesSlice = createSlice({
  name: 'purchases',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setPurchases: (state, action) => {
      state.purchases = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setSuccess: (state, action) => {
      state.success = action.payload
    },
  },
})

export const fetchAllSpecialPurchases = venueId => async dispatch => {
  dispatch(setIsLoading(true))
  try {
    const response = await API.get('api2', `specialPuurchases/${venueId}`)

    dispatch(setIsLoading(false))
    dispatch(setPurchases(response.data))
  } catch (e) {
    dispatch(setIsLoading(false))
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const sendApproveRequest = (data, venueId) => async dispatch => {
  try {
    const response = await API.post(
      'api2',
      `specialPuurchases/${venueId}/sendApproveRequest`,
      { body: data },
    )

    if (response.success) {
      SuccessNotification({ message: response?.message })
      dispatch(fetchAllSpecialPurchases(venueId))
    }
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const { setIsLoading, setPurchases, setError, setSuccess } =
  SpecialPurchasesSlice.actions

export default SpecialPurchasesSlice
