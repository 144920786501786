import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { DeleteOutlined, MenuOutlined, PlusOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'

export default function MethodSection({ handleChange }) {
  const [steps, setSteps] = useState([''])
  const { isLoading, error, success } = useSelector(state => state.recipes)

  const updateStep = (index, value) => {
    const newSteps = [...steps]
    newSteps[index] = value
    setSteps(newSteps)
  }

  const handleKeyDown = (index, e) => {
    if (e.key === 'Enter') {
      e.preventDefault()

      const currentStep = steps[index].trim()

      if (currentStep !== '' && steps.length < 20) {
        if (index === steps.length - 1) {
          setSteps([...steps])
        }
      }
    }
  }

  const deleteStep = (index, e) => {
    e.preventDefault()
    const newSteps = steps.filter((_, i) => i !== index)
    setSteps(newSteps)
  }

  // Handle drag-and-drop reordering
  const onDragEnd = result => {
    if (!result.destination) return

    const newSteps = Array.from(steps)
    const [movedStep] = newSteps.splice(result.source.index, 1)
    newSteps.splice(result.destination.index, 0, movedStep)

    setSteps(newSteps)
  }

  useEffect(() => {
    if (steps) {
      handleChange('steps', steps)
    }
  }, [steps])

  useEffect(() => {
    if (!isLoading && !error && success) {
      setSteps([''])
    }
  }, [isLoading, error, success])

  return (
    <Container>
      <Title>Method</Title>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='steps'>
          {provided => (
            <StepList ref={provided.innerRef} {...provided.droppableProps}>
              {steps.map((step, index) => (
                <Draggable
                  key={index}
                  draggableId={`step-${index}`}
                  index={index}
                >
                  {provided => (
                    <StepItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <StepNumber>{index + 1}.</StepNumber>
                      <StepInput
                        type='text'
                        placeholder={`Step ${index + 1} description...`}
                        value={step}
                        onChange={e => updateStep(index, e.target.value)}
                        onKeyDown={e => handleKeyDown(index, e)}
                      />
                      <DeleteButton onClick={e => deleteStep(index, e)}>
                        <DeleteOutlined />
                      </DeleteButton>
                      <AddButton
                        onClick={e => {
                          e.preventDefault()
                          setSteps([...steps, ''])
                        }}
                      >
                        <PlusOutlined />
                      </AddButton>
                      <DragHandle>
                        <MenuOutlined />
                      </DragHandle>
                    </StepItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </StepList>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 20px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`

const Title = styled.h2`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`

const StepList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const StepItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background: #f8f8f8;
  padding: 10px;
  border-radius: 8px;
  position: relative;
`

const StepNumber = styled.div`
  font-weight: bold;
`

const StepInput = styled.input`
  flex: 1;
  border: none;
  font-size: 16px;
  padding: 5px;
  outline: none;
  background: transparent;
`

const DeleteButton = styled.button`
  border: none;
  background: none;
  color: red;
  cursor: pointer;
`

const AddButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`

const DragHandle = styled.div`
  cursor: grab;
  color: gray;
`
