import React, { useRef, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Input, Select } from 'antd'
import { API } from 'aws-amplify'
import { useSelector } from 'react-redux'

const CustomCategorySearch = ({
  categories: initialCategories,
  handleChange,
  setOpen,
  open,
  value,
}) => {
  const [name, setName] = useState('')
  const [categories, setCategories] = useState(initialCategories)
  const venueId = useSelector(state => state.venue.venue._id)

  const inputRef = useRef(null)

  const onNameChange = event => {
    setName(event.target.value)
  }

  const addItem = async e => {
    e.preventDefault()
    if (!name.trim()) return
    const response = await API.post(
      'api2',
      `recipes/categories/${venueId}/createCategory`,
      {
        body: { catgoryName: name },
      },
    )
    setCategories([...categories, response?.category])

    setName('')
    setTimeout(() => {
      inputRef.current?.focus()
    }, 0)
  }

  return (
    <Select
      style={{ width: 1100 }}
      placeholder='Select Category'
      open={open}
      onDropdownVisibleChange={visible => {
        if (visible) setOpen(true)
      }}
      onChange={value => handleChange('category', value)}
      onSelect={() => setOpen(false)}
      value={value}
      dropdownRender={menu => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <div
            style={{
              padding: '0 8px 4px',
              display: 'flex',
              gap: '8px',
            }}
            onMouseDown={e => e.stopPropagation()}
          >
            <Input
              placeholder='Please enter item'
              ref={inputRef}
              value={name}
              onChange={onNameChange}
              onKeyDown={e => e.stopPropagation()}
              style={{ flex: 1 }}
            />
            <Button type='text' icon={<PlusOutlined />} onClick={addItem}>
              Add item
            </Button>
          </div>
        </>
      )}
    >
      {categories.map((item, index) => (
        <Select.Option key={`${item}-${index}`} value={item._id}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  )
}

export default CustomCategorySearch
