import { Input, Modal, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Title from 'src/branding/Title'
import TextArea from 'antd/lib/input/TextArea'
import { sendApproveRequest } from 'src/features/SpecialPurchasesSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getVenueSuppliers } from 'src/features/VenueSuppliersSlice'
import '../../../../features/DashboardStat/DashboardStat.css'

const { Option } = Select

const ApproveRequestModal = ({
  approveReqeustModalVisible,
  setApproveReqestModalVisible,
}) => {
  const dispatch = useDispatch()
  const venueId = useSelector(state => state.venue?.venue?._id)
  const venueSuppliers = useSelector(state =>
    state.venueSuppliers.venueSuppliers.filter(
      venueSupplier => venueSupplier.isActive,
    ),
  )

  const [payload, setPayload] = useState({
    staffName: '',
    staffEmail: '',
    sku: '',
    itemName: '',
    itemCost: 0,
    justification: '',
    managerName: '',
    managerEmail: '',
    selectedSupplier: '',
  })

  const handleChange = (key, value) => {
    setPayload(prevPayload => ({
      ...prevPayload,
      [key]: value,
    }))
  }

  const onSubmit = () => {
    dispatch(sendApproveRequest(payload, venueId))
    setPayload({
      staffName: '',
      staffEmail: '',
      itemName: '',
      itemCost: 0,
      justification: '',
      managerName: '',
      managerEmail: '',
    })
    setApproveReqestModalVisible(false)
  }

  const onChange = value => {
    handleChange('selectedSupplier', value)
  }

  useEffect(() => {
    if (venueSuppliers.length === 0) {
      dispatch(getVenueSuppliers(venueId))
    }
  }, [venueId])

  return (
    <Modal
      visible={approveReqeustModalVisible}
      onCancel={() => setApproveReqestModalVisible(false)}
      onOk={onSubmit}
      okText={'Send Request'}
    >
      <Wrapper>
        <TitleWrapper>
          <Title>Request Manager Approval</Title>
        </TitleWrapper>
        <DescriptionLine1>
          You may send a request approval to a manager before placing an order
          for high-cost items.
        </DescriptionLine1>
        <DescriptionLine2>
          {' '}
          The request is sent via email, and the manager can approve or decline
          the order.
        </DescriptionLine2>

        <Row>
          <InputComp
            value={payload.staffName}
            addonBefore={'Staff Name'}
            placeholder='Staff Name'
            onChange={e => handleChange('staffName', e.target.value)}
          />
        </Row>
        <Row>
          <InputComp
            value={payload.staffEmail}
            addonBefore={'Staff Email'}
            placeholder='Staff Email'
            onChange={e => handleChange('staffEmail', e.target.value)}
          />
        </Row>
        <Row>
          <InputComp
            value={payload.managerName}
            addonBefore={'Manager Name'}
            placeholder='Manager Name'
            onChange={e => handleChange('managerName', e.target.value)}
          />
        </Row>
        <Row>
          <InputComp
            value={payload.managerEmail}
            addonBefore={'Manager Email'}
            placeholder='Manager Email'
            onChange={e => handleChange('managerEmail', e.target.value)}
          />
        </Row>
        <Row>
          <div
            style={{
              display: 'flex',
              border: '1px solid #d9d9d9',
              backgroundColor: '#FFFFFF',
              borderRadius: '5px',
            }}
          >
            <div style={{ backgroundColor: '#fafafa' }}>
              <p
                style={{
                  margin: '8px',
                  whiteSpace: 'nowrap',
                }}
              >
                Supplier
              </p>
            </div>
            <div
              style={{
                width: '1px',
                height: '40px',
                backgroundColor: '#d9d9d9',
              }}
            ></div>

            <Select
              className='bdr-none'
              placeholder='Supplier'
              style={{
                width: '100%',
                //   outline: "none !important",
                //   boxShadow: "none !important",
                border: 'transparent !important',
              }}
              dropdownStyle={{
                border: 'none',
                boxShadow: 'none',
              }}
              onChange={onChange}
            >
              {venueSuppliers?.map(supplier => (
                <Option
                  key={supplier?.supplier?._id}
                  value={supplier?.supplier?._id}
                >
                  {supplier?.supplier?.title} &nbsp;{' '}
                  {supplier?.supplier?.secondTitle}
                </Option>
              ))}
            </Select>
          </div>
        </Row>
        <Row>
          <InputComp
            value={payload.sku}
            addonBefore={'SKU'}
            placeholder='SKU'
            onChange={e => handleChange('sku', e.target.value)}
          />
        </Row>

        <Row>
          <InputComp
            value={payload.itemName}
            addonBefore={'Item Name'}
            placeholder='Item Name'
            onChange={e => handleChange('itemName', e.target.value)}
          />
        </Row>

        <Row>
          <InputComp
            value={payload.itemCost}
            addonBefore={'Item Cost'}
            placeholder='Item Cost'
            type='number'
            onChange={e => handleChange('itemCost', e.target.value)}
          />
        </Row>

        <Row>
          <TextArea
            rows={4}
            value={payload.justification}
            placeholder='Justification (Optional)'
            onChange={e => handleChange('justification', e.target.value)}
          />
        </Row>
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div``

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 5px;
`
const DescriptionLine1 = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colours.oliveGrey};
  margin: 3px;
`
const DescriptionLine2 = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colours.oliveGrey};
  margin: 3px;
`
const InputComp = styled(Input)``

const Row = styled.div`
  margin: 15px 0;
`

export default ApproveRequestModal
